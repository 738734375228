import firebase from '../../Api/firebase'

const { auth } = firebase

const microsoftProvider = new firebase.auth.OAuthProvider('microsoft.com')
microsoftProvider.setCustomParameters({
  tenant: process.env.REACT_APP_MS_TENANT_ID,
  prompt: 'select_account'
});

class AuthService {
  static login() {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await auth().signInWithRedirect(microsoftProvider)
        resolve(data);
      } catch (error) {
        const { code } = error
        if (code === 'auth/user-not-found') {
          error.message = 'Hay un problema con su cuenta de usuario.'
        }
        reject(error)
      }
    })
  }

  static logOut() {
    return auth().signOut()
  }

  static isLoggedIn() {
    return !!auth().currentUser
  }

  static getLoggedInUser() {
    return new Promise(async (resolve, reject) => {
      try {
        const { currentUser } = await auth()

        resolve(currentUser)
      } catch (error) {
        reject(error)
      }
    })
  }
}

export default AuthService